<template>
  <div class="no-find">
    <div class="error-box">
      <h1>404</h1>
      <div>抱歉，您访问的页面不存在！</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'no-find',
  methods: {
    goBack() {
      this.$router.go(-2);
    },
  },
};
</script>

<style lang="scss" scoped>
.error-box {
  margin: 150px auto 300px;
  padding: 60px 20px;
  width: 90%;
  max-width: 900px;
  text-align: center;
  border: 1px solid #ddd;
  color: #999;
  background: #eee;
}
</style>
